import axios from "@/axios.js";

export default {
  async fetchFoodVouchers({ commit }) {
    try {
      const { data } = await axios.get(`/vancynet/food-voucher`);
      commit("setFoodVouchers", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFoodVoucher({ commit }, params) {
    try {
      const { data } = await axios.get(`/vancynet/food-voucher/${params}`);
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeFoodVoucher({ commit }, params) {
    try {
      const { data } = await axios.post(`/vancynet/food-voucher`, params);
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateFoodVoucher({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/food-voucher/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeFoodVoucher({ dispatch }, params) {
    try {
      const { data } = await axios.delete(`/vancynet/food-voucher/${params}`);
      dispatch("fetchFoodVouchers");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async fetchFoodVoucherReviews({ commit }) {
    try {
      const { data } = await axios.get(`/vancynet/food-voucher-review`);
      commit("setFoodVoucherReviews", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchFoodVoucherReview({ commit }, params) {
    try {
      const { data } = await axios.get(
        `/vancynet/food-voucher-review/${params}`
      );
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeFoodVoucherReview({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/food-voucher-review`,
        params
      );
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateFoodVoucherReview({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/food-voucher-review/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeFoodVoucherReview({ dispatch }, params) {
    try {
      const { data } = await axios.delete(
        `/vancynet/food-voucher-review/${params}`
      );
      dispatch("fetchFoodVoucherReviews");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
